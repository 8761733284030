<template>
    <div>
        <MainBannerDR :language = "lenguajeactual" />
        <SolucionesDR :language = "lenguajeactual" />
  </div>
</template>

<script>
import MainBannerDR from './MainBannerDR.vue'
import SolucionesDR from './SolucionesDR.vue'

export default {
  components: {
    MainBannerDR, SolucionesDR
  },
  data () {
    return {
      lenguajeactual: this.getlanguage()
    }
  },
  created () {
    this.track()
  },
  methods: {
    getlanguage () {
      console.log(window.location.hash)
      if (!(window.location.hash.indexOf('es') === -1)) {
        console.log('Dice Es AsyncHome')
        return 'es'
      }
      if (!(window.location.hash.indexOf('en') === -1)) {
        console.log('Dice En AsyncHome')
        return 'en'
      } else {
        console.log('Lenguaje Automático')
        return navigator.language
      }
    },
    track () {
      this.$gtag.pageview({ page_path: '/solutions/disasterrecovery' })
    }
  }
}
</script>

<style>

</style>
