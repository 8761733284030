<template>
<div>
      <section id="soluciones" class="values"  v-if="!(language.indexOf('es') === -1)">
        <div class="container" data-aos="fade-up">
          <header class="section-header">
            <h2>Soluciones a Medida</h2>
            <p>Mantené la continuidad de tu negocio</p>
          </header>
          <div class="row">
            <div class="col-lg-4">
              <router-link :to="{ name: 'Disaster Recovery', params: { userId: 123 }}" v-scroll-to="'#contact'">
              <div class="box" data-aos="fade-up" data-aos-delay="200" >
                <img src="assets/img/portfolio/dr/AuditDRP.webp" class="img-fluid" alt="">
                <h3>DRP Design</h3>
                <p style="color:#2c3e50;">Diseña con nosotros un plan integral para responder de forma rápida y efectiva.</p>
              </div>
              </router-link>
            </div>
            <div class="col-lg-4 mt-4 mt-lg-0">
              <router-link :to="{ name: 'Disaster Recovery', params: { userId: 123 }}" v-scroll-to="'#contact'" >
              <div class="box" data-aos="fade-up" data-aos-delay="400">
                <img src="assets/img/portfolio/dr/CloudDRP.webp" class="img-fluid" alt="">
                <h3>Cloud DRP</h3>
                <p style="color:#2c3e50;">Protegé tus cargas de trabajo críticas en la nube con practicamente 0 perdida de datos. Disponibilizá tus servicios en cualquier momento y respondé desde cualquier lugar. </p>
              </div>
              </router-link>
            </div>
            <div class="col-lg-4 mt-4 mt-lg-0">
              <router-link :to="{ name: 'Arcserve', params: { userId: 123 }}" >
              <div class="box" data-aos="fade-up" data-aos-delay="600">
                <img src="assets/img/portfolio/dr/Arcserve.webp" class="img-fluid" alt="">
                <h3>Arcserve Solutions</h3>
                <p style="color:#2c3e50;">Protege los servicios críticos de tu organización y prevení perdida de datos en plataformas de TI</p>
              </div>
              </router-link>
            </div>
            <div class="col-lg-4 mt-4 mt-lg-0">
              <router-link :to="{ name: 'Disaster Recovery', params: { userId: 123 }}" v-scroll-to="'#contact'">
              <div class="box" data-aos="fade-up" data-aos-delay="600">
                <img src="assets/img/portfolio/dr/Hardware.webp" class="img-fluid" alt="">
                <h3>Hardware for DRP</h3>
                <p style="color:#2c3e50;">Potenciá la respuesta de tu sitio de contingencia con la más alta tecnología. Definí con nosotros el hardware que mejor se adapta para dar respuesta ante un incidente</p>
              </div>
              </router-link>
            </div>
          </div>
        </div>
      </section>
            <section id="soluciones" class="values"  v-if="(language.indexOf('es') === -1)">
        <div class="container" data-aos="fade-up">
          <header class="section-header">
            <h2>TAILOR-MADE SOLUTIONS</h2>
            <p>Keep your business continuity</p>
          </header>
          <div class="row">
            <div class="col-lg-4">
              <router-link :to="{ name: 'Disaster RecoveryEn', params: { userId: 123 }}" v-scroll-to="'#contact'">
              <div class="box" data-aos="fade-up" data-aos-delay="200" >
                <img src="assets/img/portfolio/dr/AuditDRP.webp" class="img-fluid" alt="">
                <h3>DRP Design</h3>
                <p style="color:#2c3e50;">Design with us a comprehensive plan to respond quickly and effectively.</p>
              </div>
              </router-link>
            </div>
            <div class="col-lg-4 mt-4 mt-lg-0">
              <router-link :to="{ name: 'Disaster RecoveryEn', params: { userId: 123 }}" v-scroll-to="'#contact'" >
              <div class="box" data-aos="fade-up" data-aos-delay="400">
                <img src="assets/img/portfolio/dr/CloudDRP.webp" class="img-fluid" alt="">
                <h3>Cloud DRP</h3>
                <p style="color:#2c3e50;">Protect your critical workloads in the cloud with virtually zero data loss. Make your services available at any time and respond from anywhere. </p>
              </div>
              </router-link>
            </div>
            <div class="col-lg-4 mt-4 mt-lg-0">
              <router-link :to="{ name: 'ArcserveEn', params: { userId: 123 }}" >
              <div class="box" data-aos="fade-up" data-aos-delay="600">
                <img src="assets/img/portfolio/dr/Arcserve.webp" class="img-fluid" alt="">
                <h3>Arcserve Solutions</h3>
                <p style="color:#2c3e50;"> - </p>
              </div>
              </router-link>
            </div>
            <div class="col-lg-4 mt-4 mt-lg-0">
              <router-link :to="{ name: 'Disaster RecoveryEn', params: { userId: 123 }}" v-scroll-to="'#contact'">
              <div class="box" data-aos="fade-up" data-aos-delay="600">
                <img src="assets/img/portfolio/dr/Hardware.webp" class="img-fluid" alt="">
                <h3>Hardware for DRP</h3>
                <p style="color:#2c3e50;">Boost the response of your contingency site with the latest technology. Define with us the hardware best suited to respond to an incident.</p>
              </div>
              </router-link>
            </div>
          </div>
        </div>
      </section>
      </div>
</template>

<script>
export default {
  name: 'Home',
  props: {
    msg: String,
    language: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.col-lg-5 {
    flex: 0 0 50%;
    max-width: 50%;
}
.img-fluid {
    max-width: 100%;
    height: 236px;
    width: auto;
}

</style>
